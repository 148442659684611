import { pick } from 'lodash';

export const paymentTypes = {
  card: {
    cashSheetField: 'cards',
    reportField: 'card',
    translation: 'CARD'
  },
  cash: {
    cashSheetField: 'cash',
    reportField: 'cash',
    translation: 'CASH'
  },
  cheque: {
    cashSheetField: 'cheques',
    reportField: 'cheque',
    translation: 'CHEQUE'
  },
  giftCard: {
    cashSheetField: 'gift_cards',
    reportField: 'gift_card',
    translation: 'GIFT_CARD'
  },
  voucher: {
    cashSheetField: 'voucher',
    reportField: 'voucher',
    translation: 'VOUCHER'
  },
  spaInvite: {
    cashSheetField: 'spa_invites',
    reportField: 'spa_invite',
    translation: 'SPA_INVITE'
  },
  jpVoucher: {
    cashSheetField: 'jp_voucher',
    reportField: 'jp_voucher',
    translation: 'JP_VOUCHER'
  },
  icCard: {
    cashSheetField: 'ic_card',
    reportField: 'ic_card',
    translation: 'IC_CARD'
  },
  qr: {
    cashSheetField: 'qr',
    reportField: 'qr',
    translation: 'QR'
  },
  link: {
    cashSheetField: 'links',
    reportField: 'link',
    translation: 'PAY_BY_LINK'
  },
  bacs: {
    cashSheetField: 'bacs',
    reportField: 'bacs',
    translation: 'BACS'
  },
  lieferservice: {
    cashSheetField: 'lieferservice',
    reportField: 'lieferservice',
    translation: 'LIEFERSERVICE'
  },
  pedFailure: {
    cashSheetField: 'ped_failures',
    reportField: 'ped_failure',
    translation: 'PED_FAILURE'
  },
  clickAndCollect: {
    cashSheetField: 'click_and_collect',
    reportField: 'click_and_collect',
    translation: 'CLICK_AND_COLLECT'
  },
  point: {
    cashSheetField: 'point',
    reportField: 'point',
    translation: 'POINT'
  },
  creditSale: {
    cashSheetField: 'credit_sale',
    reportField: 'credit_sale',
    translation: 'CREDIT_SALE'
  },
  lineGift: {
    cashSheetField: 'line_gift',
    reportField: 'line_gift',
    translation: 'LINE_GIFT'
  },
  // LPAY-980 new types for Japan
  debitCard: {
    cashSheetField: 'debit_card',
    reportField: 'debit_card',
    translation: 'DEBIT_CARD'
  },
  prepaidCard: {
    cashSheetField: 'prepaid_card',
    reportField: 'prepaid_card',
    translation: 'PREPAID_CARD'
  },
  cashDelivery: {
    cashSheetField: 'cash_on_delivery',
    reportField: 'cash_on_delivery',
    translation: 'CASH_DELIVERY'
  },
  cashRegister: {
    cashSheetField: 'cash_register',
    reportField: 'cash_register',
    translation: 'CASH_REGISTER'
  },
  directSales: {
    cashSheetField: 'direct_sales',
    reportField: 'direct_sales',
    translation: 'DIRECT_SALES'
  },
  delivery: {
    cashSheetField: 'delivery',
    reportField: 'delivery',
    translation: 'DELIVERY'
  },
  pasmo: {
    cashSheetField: 'pasmo',
    reportField: 'pasmo',
    translation: 'PASMO'
  },
  buyAGift: {
    cashSheetField: 'buy_a_gift',
    reportField: 'buy_a_gift',
    translation: 'BUY_A_GIFT'
  },
  virginExperience: {
    cashSheetField: 'virgin_experience',
    reportField: 'virgin_experience',
    translation: 'VIRGIN_EXPERIENCE'
  },
  giftee: {
    cashSheetField: 'giftee',
    reportField: 'giftee',
    translation: 'GIFTEE'
  },
  // LPC-1800 new types for Mohegan Sun
  moheganCoupon: {
    cashSheetField: 'mohegan_coupon',
    reportField: 'mohegan_coupon',
    translation: 'MOHEGAN_COUPON'
  },
  moheganPoint: {
    cashSheetField: 'mohegan_point',
    reportField: 'mohegan_point',
    translation: 'MOHEGAN_POINT'
  },
  moheganGiftCard: {
    cashSheetField: 'mohegan_gift_card',
    reportField: 'mohegan_gift_card',
    translation: 'MOHEGAN_GIFT_CARD'
  },
  moheganComp: {
    cashSheetField: 'mohegan_comp',
    reportField: 'mohegan_comp',
    translation: 'MOHEGAN_COMP'
  },
  // LPC-1994 new types for Puerto Rico Card
  puertoRicoCredit: {
    cashSheetField: 'puerto_rico_credit',
    reportField: 'puerto_rico_credit',
    translation: 'PUERTO_RICO_CREDIT'
  }
};

export const ukPaymentTypes = pick(paymentTypes, [
  'card',
  'cash',
  'cheque',
  'giftCard',
  'voucher',
  'spaInvite',
  'link',
  'bacs',
  'pedFailure',
  'buyAGift',
  'virginExperience'
]);

export const iePaymentTypes = pick(paymentTypes, [
  'card',
  'cash',
  'cheque',
  'giftCard',
  'voucher',
  'spaInvite',
  'link',
  'bacs',
  'pedFailure'
]);

export const japanPaymentTypes = pick(paymentTypes, [
  'card',
  'cash',
  'giftCard',
  'spaInvite',
  'jpVoucher',
  'icCard',
  'qr',
  'point',
  'creditSale',
  'lineGift',
  'debitCard',
  'prepaidCard',
  'cashDelivery',
  'cashRegister',
  'directSales',
  'delivery',
  'pasmo',
  'giftee'
]);

export const germanyPaymentTypes = pick(paymentTypes, [
  'card',
  'cash',
  'giftCard',
  'voucher',
  'lieferservice',
  'clickAndCollect'
]);

export const usPaymentTypes = pick(paymentTypes, ['card', 'cash', 'giftCard']);

export const usWithMoheganPaymentTypes = pick(paymentTypes, [
  'card',
  'cash',
  'giftCard',
  'moheganCoupon',
  'moheganPoint',
  'moheganGiftCard',
  'moheganComp'
]);

export const usWithPuertoRicoCreditPaymentTypes = pick(paymentTypes, ['card', 'cash', 'giftCard', 'puertoRicoCredit']);
